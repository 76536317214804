import { Injectable } from '@angular/core';
import { ParticipantService } from '../participant/participant.service';
import { UserService } from '../user/user.service';
import { ChatService } from '../chat/chat.service';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class HandRaisedService {

    get localParticipant() {
        return this.participantService.localParticipant;
    }

  constructor(
    private participantService: ParticipantService,
    private userService: UserService,
    private chatService: ChatService,
  ) { }

  handRaisedMessage(enabled: boolean) {
        const messageBody = {
            message: 'Raised Hand',
            type: 'raised-hand',
            isHandRaised: enabled,
            participantId: this.localParticipant?.id,
            participantName: this.userService.userName,
            timeStamp: new Date().toISOString(),
            role: this.userService.role,
        }
        this.participantService.ownHandRaised.next({
            isHandRaised: enabled,
            participantId: this.localParticipant?.id,
            participantName: this.userService.userName,
        });
        this.chatService.callObject.sendAppMessage(messageBody, "*");
    }
}
